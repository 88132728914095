import React, { useState, useCallback } from 'react';

const UseFormForBrandSetting = (initValue, validation) => {
    const [value, setValue] = useState(initValue);

    const setInitValue = useCallback((data) => {
        let copyData = {...data};

        let targetData = {...value, ...copyData};

        if(!targetData.siteInfo) {
            targetData.siteInfo = {
                keyword: '',
                description: '',
                siteNameKorean: '',
                siteNameEnglish: '',
                naverSiteKey: '',
            };
        }

        if(!targetData.smtp) {
            targetData.smtp = {
                email: '',
                password: '',
            };
        }
        
        if(!targetData.facebookConv) {
            targetData.facebookConv = [];
        }

        if(!targetData.tiktokConv) {
            targetData.tiktokConv = [];
        }
        targetData.firebaseServiceKey = JSON.stringify(targetData.firebaseServiceKey);
        setValue({ ...targetData });
    },[]);

    const onChange = useCallback((event) => {
        if(event.target) {
            let targetValue = event.target.value;
            let targetName = event.target.name;

            if(targetName === 'domain') {
                if(targetValue.replace(/https:\/\//g,"") === '') {
                    targetValue = '';
                } else {
                    const pattern = /https:\/\//;
                    if(!pattern.test(targetValue)) {
                        targetValue = `https://${targetValue}`;
                    }
                }
            } else if(
                targetName === 'deliveryPrice' ||
                targetName === 'freeShippingStandard' ||
                targetName === 'deliverySetting.island' ||
                targetName === 'deliverySetting.jeju' ||
                targetName === 'deliverySetting.returnDeliveryPrice' ||
                targetName === 'deliverySetting.exchangeDeliveryPrice') {
                if (targetValue) {
                    targetValue = targetValue.replace(/[^0-9]/g,'');
                    targetValue = targetValue.replace(/,/g, "");
                }
            }
            targetName = event.target.name.split(".");

            if(targetName.length > 1) {
                setValue({...value, [targetName[0]]: { ...value[targetName[0]], [targetName[1]]: targetValue } });
            } else {
                setValue({...value, [event.target.name]: targetValue});
            }
        }
    }, [value]);

    const onChangeImage = useCallback(e => {
        const imageFile = e.target.files[0];
        const reader = new FileReader();
        if(e.target.name === 'logo') {
            reader.readAsDataURL(imageFile);
            reader.onload = () => {
                setValue({...value, img: imageFile, preview: reader.result});
            };
        } else if(e.target.name === 'favicon') {
            reader.readAsDataURL(imageFile);
            reader.onload = () => {
                setValue({...value, favicon: imageFile, faviconPreview: reader.result});
            };
        } else if(e.target.name === 'metaImage') {
            reader.readAsDataURL(imageFile);
            reader.onload = () => {
                setValue({...value, metaImage: imageFile,  metaImagePreview: reader.result});
            };
        } else if(e.target.name === 'logo2'){
            reader.readAsDataURL(imageFile);
            reader.onload = () => {
                setValue({...value, logo2: imageFile,  logo2Preview: reader.result});
            };
        }
    }, [value]);

    const onChangeArray = useCallback((event) => {
        if(event.target) {
            let targetValue = event.target.value;
            let targetName = event.target.name;

            targetName = event.target.name.split(".");

            let temp = [...value[targetName[0]]];
            temp[targetName[2]] = {...temp[targetName[2]], [targetName[1]] : targetValue};
            
            setValue({...value, [targetName[0]] : temp});
        }
    }, [value]);

    const addConv = useCallback((e) => {
        let addOption = [...value[e], ({ id: '', access_token: '', memo: '' })];
        setValue({...value, [e] : addOption});
    },[value]);

    const removeConv = useCallback((e, n) => {
        let filtering = [...value[e]].filter((data,index) => index !== n);
        setValue({...value, [e] : filtering});
    },[value]);

    return { value, onChange, setInitValue, onChangeImage, onChangeArray, addConv, removeConv };
};

export default UseFormForBrandSetting;
